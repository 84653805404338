import React, { Component } from 'react';
import Navbar from "../components/navbar"
import Profilebar from "../components/profilebar"
import ModuleStatistiqueMin from "../components/moduleStatistiqueMin"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, } from '@fortawesome/free-regular-svg-icons'
import { faHandHoldingUsd, faPercentage, faRunning, faWalking, faStreetView } from '@fortawesome/free-solid-svg-icons'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip, ResponsiveContainer } from 'recharts';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataUser: this.props.dataUser,
            clients: 0,
            progressPercentNumberVE: 0,
            numbercustomersabsentfirstvisit: 0,
            dateBreakdownsOverYear: []
        }
    }

    componentDidMount() {
        this.getNumberBreakdownsOverYear()
    }



    getNclient() {
        var axios = require('axios');
        var tokenJWT = localStorage.getItem("accessToken")

        var config = {
            method: 'get',
            url: process.env.REACT_APP_PortailPartenaireAPI + "/api/s/nbclient",
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };
        return axios(config).then((response) => {
            return response.data.count
        }).catch((error) => {
            //popup 
            console.log(error)
            return 0
        })
    }

    getProgressPercentNumberVE() {
        var axios = require('axios');
        var tokenJWT = localStorage.getItem("accessToken")

        var config = {
            method: 'get',
            url: process.env.REACT_APP_PortailPartenaireAPI + "/api/s/progressPercentNumberVE",
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        return axios(config).then((response) => {
            return response.data.count
        }).catch((error) => {
            //popup 
            console.log(error)
            return 0
        })
    }

    getNumbercustomersabsentfirstvisit() {
        var axios = require('axios');
        var tokenJWT = localStorage.getItem("accessToken")

        var config = {
            method: 'get',
            url: process.env.REACT_APP_PortailPartenaireAPI + "/api/s/numbercustomersabsentfirstvisit",
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        return axios(config).then((response) => {
            return response.data.count
        }).catch((error) => {
            //popup 
            console.log(error)
            return 0
        })
    }

    getNumbercustomersabsentsecondvisit() {
        var axios = require('axios');
        var tokenJWT = localStorage.getItem("accessToken")

        var config = {
            method: 'get',
            url: process.env.REACT_APP_PortailPartenaireAPI + "/api/s/numbercustomersabsentsecondvisit",
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        return axios(config).then((response) => {
            return response.data.count
        }).catch((error) => {
            //popup 
            console.log(error)
            return 0
        })
    }

    getNumberCustomersRemainingVisit() {
        var axios = require('axios');
        var tokenJWT = localStorage.getItem("accessToken")

        var config = {
            method: 'get',
            url: process.env.REACT_APP_PortailPartenaireAPI + "/api/s/numberCustomersRemainingVisit",
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        return axios(config).then((response) => {
            return response.data.count
        }).catch((error) => {
            //popup 
            console.log(error)
            return 0
        })
    }

    getOutstandingInvoiceBalances() {
        var axios = require('axios');
        var tokenJWT = localStorage.getItem("accessToken")

        var config = {
            method: 'get',
            url: process.env.REACT_APP_PortailPartenaireAPI + "/api/s/outstandingInvoiceBalances",
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        return axios(config).then((response) => {
            return response.data.count
        }).catch((error) => {
            //popup 
            console.log(error)
            return 0
        })
    }

    getNumberBreakdownsOverYear() {
        var axios = require('axios');
        var tokenJWT = localStorage.getItem("accessToken")

        var config = {
            method: 'get',
            url: process.env.REACT_APP_PortailPartenaireAPI + "/api/s/numberBreakdownsOverYear",
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        return axios(config).then((response) => {
            var mouth = {
                "janvier": { y: 'jan' },
                "fevrier": { y: 'fev' },
                "mars": { y: 'mar' },
                "avril": { y: 'avr' },
                "mai": { y: 'mai' },
                "juin": { y: 'jui' },
                "juiller": { y: 'jui' },
                "aout": { y: 'aou' },
                "septembre": { y: 'sep' },
                "octobre": { y: 'oct' },
                "novembre": { y: 'nov' },
                "decembre": { y: 'dec' }
            }

            for (var line in response.data) {
                //console.log(response.data[line])
                for (var y in response.data[line]) {
                    var x = response.data[line][y]
                    mouth[y][line] = x
                }
            }
            var data = []
            for (var index in mouth) {
                data.push(mouth[index])
            }
            this.setState({
                dateBreakdownsOverYear: data
            })
        }).catch((error) => {
            //popup 
            console.log(error)
            return 0
        })
    }


    render() {
        //console.log(process.env.REACT_APP_AuthAPI, this.state.dataUser)    
        return (
            <>
                <Navbar where={"home"} />
                <div className="flex flex-col w-full">
                    <Profilebar username={this.state.dataUser.username} />
                    <div className="px-[3vw] py-[1vw] bg-gray-200 h-full">
                        <h1 className='mb-5 text-xl'> Aperçu </h1>
                        <div className='grid grid-cols-4 gap-x-5 gap-y-5 mb-5 '>
                            <ModuleStatistiqueMin textColors={"text-green-600"} borderColors={"border-green-600"} icon={faUser} titre={"Locataires"} getData={this.getNclient}></ModuleStatistiqueMin>
                            <ModuleStatistiqueMin textColors={"text-gray-600"} borderColors={"border-gray-600"} icon={faWalking} titre={"Locataires absents à la première visite"} getData={this.getNumbercustomersabsentfirstvisit}></ModuleStatistiqueMin>
                            <ModuleStatistiqueMin textColors={"text-gray-600"} borderColors={"border-gray-600"} icon={faRunning} titre={"Locataires absents à la deuxième visite"} getData={this.getNumbercustomersabsentsecondvisit}></ModuleStatistiqueMin>
                            <ModuleStatistiqueMin textColors={"text-gray-600"} borderColors={"border-gray-600"} icon={faStreetView} titre={"Locataire avec VE à réaliser"} getData={this.getNumberCustomersRemainingVisit}></ModuleStatistiqueMin>
                            <ModuleStatistiqueMin textColors={"text-red-600"} borderColors={"border-red-600"} icon={faHandHoldingUsd} titre={"Soldes des factures impayées"} unit='€' getData={this.getOutstandingInvoiceBalances}></ModuleStatistiqueMin>
                            <ModuleStatistiqueMin textColors={"text-blue-600"} borderColors={"border-blue-600"} icon={faPercentage} titre={"L'avancement en % du nombre de VE"} unit='%' getData={this.getProgressPercentNumberVE}></ModuleStatistiqueMin>
                        </div>
                        <div className='grid grid-cols-4 gap-5  '>
                            <div className='col-span-2 flex flex-col bg-white text-gray-600 pt-3 pb-4 px-5 rounded-sm '>
                                <h1 className='font-medium mb-4 '>Nombre de pannes sur le parc par année civile</h1>
                                <div className='mx-auto h-[300px] w-full'>
                                    {this.state.dateBreakdownsOverYear.length != [] ? <ResponsiveContainer width="100%" height="100%">
                                        <LineChart width={700} height={300} data={this.state.dateBreakdownsOverYear} >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="y" />
                                            <YAxis label={{ value: 'Nb pannes', angle: -90, position: 'insideLeft' }} />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="2021" stroke="#82ca9d" />
                                            <Line type="monotone" dataKey="2022" stroke="#8884d8" />
                                            <Line type="monotone" dataKey="2023" stroke="#f59e0b" />
                                            <Line type="monotone" dataKey="2024" stroke="#e11d48" />
                                        </LineChart>
                                    </ResponsiveContainer> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Home