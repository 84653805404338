import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class notifBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: this.props.message,
            color: this.props.color
        }

    }

    notifColor() {
        switch (this.state.color) {
            case "red":
                return "bg-red-100 border-red-400 text-red-700"

            case "blue":
                return "bg-blue-100 border-blue-400 text-blue-700"

            case "green":
                return "bg-green-100 border-green-400 text-green-700"

            default:
                return "bg-red-100 border-red-400 text-red-700"
        }
    }

    render() {


        return (
            <div className={" border " + (this.props.px || "px-4") + " " + (this.props.py || "py-3") + " rounded" + " " + this.notifColor() + " " + this.state.display} role="alert">
                <span className="block sm:inline">
                    {
                        this.state.message
                    }
                </span>
            </div>
        );
    }
}

export default notifBar;