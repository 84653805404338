import React, { Component } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import Navbar from "../components/navbar"
import Profilebar from "../components/profilebar"
import DynamicTable from "../components/dynamicTable"
import structureTableBase from '../assets/table/structure_base_liste_client.json'
import LoadingTable from "../components/loadingTable"
import LoadingBar from "../components/loadBar"

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataUser: this.props.dataUser,
            structureTable: structureTableBase,
            dataTable: null,
            dataTableCount: 0,
            dataPage: 0,
            useSearchParams: this.props.useSearchParams,
            search: ""
        }
        this.fonctionRow = this.fonctionRow.bind(this);
        this.getDataTable = this.getDataTable.bind(this);
        this.scrollFunction = this.scrollFunction.bind(this);
        this.searchParams = this.searchParams.bind(this);

    }

    async componentDidMount() {
        await this.searchParams()
        this.getDataTable()
    }

    searchParams() {
        var [searchParams] = this.state.useSearchParams
        if (searchParams.get('search')) {
            this.setState({
                search: "/" + searchParams.get('search')
            })
            searchParams.delete('search')
        }
    }

    fonctionRow(row) {
        this.props.navigation(`/clients/${row["CLIEN_CLIEN"]}`)
    }

    async reset() {
        this.props.navigation('/clients')
        await this.setState({
            search: ""
        });
        this.getDataTable();
    }

    //fonction qui est lancer quand on accede a la page, elle permet de recuper la liste des clients sur l'api 
    getDataTable() {
        var axios = require('axios');
        var PortailPartenaireAPI = process.env.REACT_APP_PortailPartenaireAPI
        var tokenJWT = localStorage.getItem("accessToken")

        var config = {
            method: 'get',
            url: PortailPartenaireAPI + '/api/c/partenaire' + this.state.search + '?size=50&page=0',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        axios(config).then((response) => {
            this.setState({
                dataTable: response.data.rows,
                dataTableCount: response.data.count
            });
        }).catch((error) => {
            //popup 
            console.log(error)
            /*if (error.response.statusText == "Unauthorized") {
                this.props.navigation('/unauthorizedPage')
            } else {
                this.props.navigation('/logout')
            }*/
        })
    }
    //cette fonction gere le scroll / pagination de la liste des client 
    scrollFunction(callBack = null) {
        //update dataTable
        if (this.state.dataPage < Math.ceil(this.state.dataTableCount / 50)) {
            var page = this.state.dataPage + 1
            var axios = require('axios');
            var PortailPartenaireAPI = process.env.REACT_APP_PortailPartenaireAPI
            var tokenJWT = localStorage.getItem("accessToken")

            var config = {
                method: 'get',
                url: PortailPartenaireAPI + '/api/c/partenaire' + this.state.search + '?size=50&page=' + page,
                headers: {
                    'Authorization': 'Bearer ' + tokenJWT
                }
            };

            axios(config).then(async (response) => {
                await this.setState({
                    dataTable: this.state.dataTable.concat(response.data.rows),
                    dataPage: page
                });
                callBack()
            }).catch((error) => {
                //popup 
                console.log(error)
                /*if (error.response.statusText == "Unauthorized") {
                    this.props.navigation('/unauthorizedPage')
                } else {
                    this.props.navigation('/logout')
                }*/
            })
        } else {
            callBack()
        }
    }

    render() {
        return (
            <>
                <Navbar where={"clients"} />
                <div className="flex flex-col w-full h-screen">
                    <Profilebar username={this.state.dataUser.username} />
                    <div className="px-[3vw] py-[1vw] bg-gray-200 h-full">
                        <div className='flex'>
                            {this.state.dataTable == null ? <h1 className='mb-5'><LoadingBar maxWidth='max-w-[12vw]' height='h-7' /></h1> : <h1 className='mb-5 text-xl'>Liste locataires <span className='text-sm'>({this.state.dataTableCount})</span> </h1>}
                            {this.state.search != "" ? <div onClick={() => { this.reset() }} className="group ml-auto mt-1 mb-auto text-white right-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer"><FontAwesomeIcon icon={faRedoAlt} className="mr-1 group-hover:animate-spin" />Réinitialiser</div> : null}
                        </div>
                        {this.state.dataTable
                            ? <DynamicTable scrollFunction={this.scrollFunction} structureTable={this.state.structureTable} dataTable={this.state.dataTable} optionRow={"link"} fonctionRow={this.fonctionRow} rounded={"false"} ></DynamicTable>
                            : <LoadingTable numCol={10} numRow={19} rounded={"false"} />}
                    </div>
                </div>
            </>
        )
    }
}

export default (props) => (
    <Clients
        {...props}
        useSearchParams={useSearchParams()}
        navigation={useNavigate()}
    />
);