import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default class moduleStatistiqueMin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textColors: this.props.textColors,
            borderColors: this.props.borderColors,
            icon: this.props.icon,
            titre: this.props.titre,
            value: 0,
            unit: this.props.unit || null,
            getDate: this.props.getData,

        }
    }

    async componentDidMount() {
        this.setState({
            value: await this.state.getDate()
        })

        if (this.state.value != 0) {
            const obj = document.getElementById('id' + this.state.titre);
            this.animateValue(obj, this.state.value, 750);
        }
    }




    animateValue(obj, end, duration) {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            obj.innerHTML = Math.floor(progress * end);
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }

    render() {
        return (
            <div className='flex flex-col bg-white text-gray-600 py-3 px-5 rounded-sm '>
                <div className='flex my-auto'>
                    <div className={'flex  w-1/6 max-w-[2.5rem] h-10 border-2 rounded-full my-auto mr-3 ' + this.state.borderColors}>
                        <FontAwesomeIcon icon={this.state.icon} className={"m-auto h-5 " + this.state.textColors} />
                    </div>
                    <div className='flex w-5/6 flex-col my-auto font-medium '>
                        <div className={this.state.textColors}> <span id={'id' + this.state.titre}> 0 </span> {this.state.unit} </div>
                        <p className=' text-sm'>{this.state.titre}</p>
                    </div>
                </div>
            </div>
        );
    }
}

