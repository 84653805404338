import React, { Component } from 'react';
import { NavLink } from "react-router-dom"
import Navbar from "../components/navbar"
import Profilebar from "../components/profilebar"
import NotifBar from '../components/notifBar';
import ExportReclamation from '../components/export/exportReclamation';
import ExportAbsentVisite from '../components/export/exportAbsentVisite';
import ExportInterventionProgrammer from '../components/export/exportInterventionProgrammer';
import ExportClient from '../components/export/exportClient';
import ExportPanne from '../components/export/exportPanne';
import ExportIntervention from '../components/export/exportIntervention';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

class Exports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PortailPartenaireAPI: process.env.REACT_APP_PortailPartenaireAPI,
            dataUser: this.props.dataUser,
            years: new Date().getFullYear(),
            month: new Date().getMonth(),
            ListMonth: [
                "janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"
            ],
            displayAlert: false,
            messageAlert: "",
            colorAlert: "",
        }
        this.upMonth = this.upMonth.bind(this);
    }

    displayAlert(messageAlert, colorAlert) {
        this.setState({
            displayAlert: !this.state.displayAlert,
            messageAlert: messageAlert,
            colorAlert: colorAlert,
        })
        setTimeout(() => {
            this.setState({
                displayAlert: !this.state.displayAlert
            })
        }, 6000);
    }

    upMonth(up) {
        var month = this.state.month
        var years = this.state.years
        if (up == "-") {
            if (this.state.month == 0) {
                month = 11
                years = years - 1
            } else {
                month = month - 1
            }
        } else if (up == "+") {
            if (years == new Date().getFullYear() && month == new Date().getMonth()) {

            } else {
                if (this.state.month == 11) {
                    month = 0
                    years = years + 1
                } else {
                    month = month + 1
                }
            }
        }
        this.setState({
            month: month,
            years: years
        });
    }

    render() {
        //console.log(process.env.REACT_APP_EcommAPI)    
        return (
            <>
                <Navbar where={"exports"} />
                <div className="flex flex-col w-full">
                    <Profilebar username={this.state.dataUser.username} />
                    <div className="px-[3vw] py-[1vw] bg-gray-200 h-full">
                        {this.state.displayAlert == true ? <div className=" w-fit mx-auto"><NotifBar display={this.state.displayAlert} color={this.state.colorAlert} message={this.state.messageAlert} ></NotifBar> </div> : null}
                        <h1 className='mb-5 text-xl'> Exports </h1>
                        <div className='flex flex-col'>
                            <div className='grid grid-cols-4 gap-10 mb-10 '>
                                <ExportClient ifError={(message) => this.displayAlert(message, "red")} />
                                <ExportInterventionProgrammer ifError={(message) => this.displayAlert(message, "red")} />
                                <ExportAbsentVisite ifError={(message) => this.displayAlert(message, "red")} />
                                <ExportReclamation ifError={(message) => this.displayAlert(message, "red")} />
                            </div>
                            <div className='grid grid-cols-4 gap-10 mb-3 '>
                                <div className='flex bg-white px-2 py-2 rounded'>
                                    <div className="w-6 h-6 flex">
                                        <div onClick={() => this.upMonth("-")} className='w-full h-full border text-sm flex text-gray-600 hover:text-white hover:bg-[#a5a4bf] rounded cursor-pointer'> <FontAwesomeIcon icon={faChevronLeft} className="m-auto" /> </div>
                                    </div>
                                    <div className="m-auto font-medium"> {this.state.ListMonth[this.state.month]} {this.state.years}</div>
                                    <div className="w-6 h-6 flex">
                                        {this.state.years == new Date().getFullYear() && this.state.month == new Date().getMonth()
                                            ? null
                                            : <div onClick={() => this.upMonth("+")} className='w-full h-full border text-sm flex text-gray-600 hover:text-white hover:bg-[#a5a4bf] rounded cursor-pointer'> <FontAwesomeIcon icon={faChevronRight} className="m-auto" /> </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className=' grid grid-cols-4 gap-10'>
                                <ExportIntervention ifError={(message) => this.displayAlert(message, "red")} years={this.state.years} month={this.state.month} />
                                <ExportPanne ifError={(message) => this.displayAlert(message, "red")} years={this.state.years} month={this.state.month} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id='spinner' className=" min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div className="flex justify-center items-center h-full">
                        <img className="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
                    </div>
                </div>
            </>
        )
    }
}

export default Exports