export function downloadString(text, fileType, fileName) {
    try {

        // Ajouter le BOM (Byte Order Mark) pour UTF-8 au début du texte par excel il fait chier
        const bom = '\uFEFF';
        const textWithBom = bom + text;
        // Créer le Blob avec l'encodage UTF-8 avec BOM
        var blob = new Blob([textWithBom], { type: fileType + ";charset=utf-8" });
        var a = document.createElement('a');
        a.download = fileName;
        a.href = URL.createObjectURL(blob);
        a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(function () { URL.revokeObjectURL(a.href); }, 1500);
        document.getElementById('spinner').classList.add("hidden")
    } catch (error) {
        document.getElementById('spinner').classList.add("hidden")
        throw new Error("Une erreur est survenue lorsque de la récupération des données");
    }
}

export function convertJsonToCsv(jsonTable, header = null, headerClean = null) {
    //console.log(jsonTable)
    var items = jsonTable
    var replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    try {
        var arr = jsonTable.map(vals => JSON.stringify(vals).split('":"').length)
        var key = arr.indexOf(Math.max.apply(null, arr))
        header = header == null ? recupererHeaders(jsonTable[key]) : header

        //console.log(jsonTable, header)

        var csv = [
            headerClean == null ? header.join(';') : headerClean.join(';'), // header row first
            ...items.map(row => header.map((fieldName) => { try { return JSON.stringify(eval("row." + fieldName), replacer) } catch (e) { return null } }).join(';'))
        ].join('\r\n')
    } catch (error) {
        //console.log(error)
        document.getElementById('spinner').classList.add("hidden")
        throw new Error(error);
    }
    //console.log(csv)
    return csv
}

export function convertToHourMinute(value) {

    // Convertir la valeur en nombre
    var numericValue = parseInt(value, 10);

    // Vérifier si la conversion est valide
    if (isNaN(numericValue)) {
        return value; // Retourner la valeur d'origine si la conversion échoue
    }

    // Extraire les heures et les minutes
    var hours = Math.floor(numericValue / 100); // Extraire les deux premiers chiffres
    var minutes = numericValue % 100; // Extraire les deux derniers chiffres

    // Formater les heures et les minutes
    var formattedHours = hours.toString().padStart(2, '0'); // Ajouter un zéro devant si nécessaire
    var formattedMinutes = minutes.toString().padStart(2, '0'); // Ajouter un zéro devant si nécessaire

    // Retourner le format "hh:mm"
    return formattedHours + 'h' + formattedMinutes;
}

function recupererHeaders(val, array = [], parent = null) {
    for (var j in val) {
        if (typeof val[j] == 'object') {
            if (Array.isArray(val[j])) {
                val[j].map((vals, key) => {
                    recupererHeaders(vals, array, (parent ? parent + "." : "") + j + "[" + key + "]")
                })
            } else {
                recupererHeaders(val[j], array, (parent ? parent + "." : "") + j)
            }
        } else {
            array.push(parent ? parent + "." + j : j)
        }
    }
    return array
}

export function getDataTable(url) {
    document.getElementById('spinner').classList.remove("hidden")
    var config = {
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
        }
    };

    config["url"] = url


    try {
        var axios = require('axios');
        return axios(config)
            .then(async (response) => {
                //console.log(response);       
                return response.data.rows;
            })
            .catch((error) => {
                document.getElementById('spinner').classList.add("hidden")
                throw new Error("Une erreur est survenue lorsque de la récupération des données");
            })
    } catch (error) {
        document.getElementById('spinner').classList.add("hidden")
        throw new Error("Une erreur est survenue lorsque de la récupération des données");
    }
}
