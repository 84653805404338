import logo from './logo.svg';
import './App.css';
import { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Login from './pages/security/login'
import Logout from './pages/security/logout';
import Authenticator from './components/security/authenticator'
import UnauthorizedPage from "./pages/security/unauthorizedPage"

import NotFound from './pages/notFound';

import Clients from './pages/clients';
import Ficheclient from './pages/ficheClient';

import Home from './pages/home';
import Planning from './pages/planning';
import Exports from './pages/exports';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataUser: null
    }
    this.setUser = this.setUser.bind(this)
    this.getUser = this.getUser.bind(this)
    this.clearUser = this.clearUser.bind(this)

  }

  getUser() {
    return this.state.dataUser
  }

  setUser(dataUser) {
    this.setState({
      dataUser: dataUser
    })
    let time = localStorage.getItem("deadLineAuth") ? localStorage.getItem("deadLineAuth") : dataUser.exp
    let idTimeout = setTimeout(() => { window.location.replace("/logout") }, time * 1000 - Math.floor(Date.now()))
    this.setState({
      idTimeout: idTimeout
    })
  }

  clearUser() {
    this.setState({
      dataUser: null
    })
    clearTimeout(this.state.idTimeout)
    //console.log('clean User')
  }
  render() {
    return (
      <BrowserRouter>
        <Routes >
          <Route path="*" exact element={<NotFound />} />
          <Route path="/unauthorizedPage" exact element={<UnauthorizedPage />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/resetpassword/:token" element={<Login display="resetPassword" />} />
          <Route path="/logout" exact element={<Logout clearUser={this.clearUser} />} />
          <Route path="/" exact element={<Authenticator getUser={this.getUser} setUser={this.setUser} ArrayRoles={["ROLE_PARTENAIRE"]}><Home /></Authenticator>} />

          <Route path="/clients/:idClient" element={<Authenticator getUser={this.getUser} setUser={this.setUser} ArrayRoles={["ROLE_PARTENAIRE"]}><Ficheclient /></Authenticator>} />
          <Route path="/clients" exact element={<Authenticator getUser={this.getUser} setUser={this.setUser} ArrayRoles={["ROLE_PARTENAIRE"]}><Clients /></Authenticator>} />

          <Route path="/planning" element={<Authenticator getUser={this.getUser} setUser={this.setUser} ArrayRoles={["ROLE_PARTENAIRE"]}><Planning /></Authenticator>} />
          <Route path="/exports" element={<Authenticator getUser={this.getUser} setUser={this.setUser} ArrayRoles={["ROLE_PARTENAIRE"]}><Exports /></Authenticator>} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
