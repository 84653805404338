import React, { Component } from 'react';
import { downloadString, convertJsonToCsv, getDataTable } from './outilExport';

export default class ExportInterventionProgrammer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            PortailPartenaireAPI: process.env.REACT_APP_PortailPartenaireAPI,
            ifError: this.props.ifError
        }
    }


    async handleClick() {
        var fileName = "Export_interventions_planifiées_" + new Date().toLocaleDateString().replace("/", "_")
        var header = ['INTE1_INTER', 'INTE1_DATIN', 'INTE1_JOUR', 'INTE1_PERIO', 'INTE1_TYPE', 'INTE1_LIBIN', 'INTE1_DATAP', 'INTE1_HEURA', 'OXYG_CLIEN.CLIEN_CLIEN', 'OXYG_CLIEN.CLIEN_NOM', 'OXYG_CLIEN.CLIEN_PRENO', 'OXYG_CLIEN.CLIEN_NUMRU', 'OXYG_CLIEN.CLIEN_QUARU', 'OXYG_CLIEN.CLIEN_RUE1', 'OXYG_CLIEN.CLIEN_CODPO', 'OXYG_CLIEN.CLIEN_VILLE', 'OXYG_CLIEN.CLIEN_REGIE', 'OXYG_CLIEN.CLIEN_IMMEU', 'OXYG_CLIEN.OXYG_IMMEU.IMMEU_NAGCOLL']
        var headerClean = ['Numero intervention', 'Date intervention', "Jour d'intervention", 'Periode', 'Type', 'Libelle', "Date d'appel", 'HeurA', 'Numero client', 'Nom', 'Prenom', 'Numero', 'Quaru', 'Rue', 'Code postal', 'Ville', 'Regie', 'Immeuble', 'Nagcoll']

        try {
            var data = await getDataTable(this.state.PortailPartenaireAPI + "/api/i")

            var csv = await convertJsonToCsv(data, header, headerClean)

            downloadString(csv, "text/csv", fileName)
        } catch (error) {
            console.error(error);
            this.state.ifError(error.message, 'red')
        }

    }


    render() {

        return (
            <div onClick={() => this.handleClick()} className='flex flex-col bg-white text-gray-600 hover:text-white hover:bg-[#a5a4bf] py-3 pb-8 px-3 rounded cursor-pointer'>
                <p className='mb-4 font-medium'>Liste des interventions programmées</p>
                <img className='h-[5vw] mx-auto' src={"/images/microsoft-excel.png"} alt="Logo microsoft excel" />
            </div>
        );
    }
}