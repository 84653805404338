import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import LoadingBar from "./loadBar"

class DynamicTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rounded: this.props.rounded || "rounded-lg",
            maxHeightTable: this.props.maxHeightTable || "max-h-[81vh]",
            structureTable: this.props.structureTable,
            dataTable: this.props.dataTable,
            optionRow: this.props.optionRow || null,
            navigation: this.props.navigation || null,
            fonctionRow: this.props.fonctionRow || null
        }
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.checkPropsDataTable(),
            30
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    checkPropsDataTable() {
        if (this.state.dataTable != this.props.dataTable) {
            this.setState({
                dataTable: this.props.dataTable
            });
        }
    }

    async scrollFunction(element) {
        //console.log(Math.round(element.currentTarget.scrollTop), Math.round(element.currentTarget.scrollHeight), Math.round(element.currentTarget.clientHeight))
        if (document.getElementById("leadRow").classList.contains("hidden") &&
            Math.round(element.currentTarget.scrollTop) >= (Math.round(element.currentTarget.scrollHeight) - Math.round(element.currentTarget.clientHeight) - 1) &&
            Math.round(element.currentTarget.scrollTop) <= (Math.round(element.currentTarget.scrollHeight) - Math.round(element.currentTarget.clientHeight) + 1)
        ) {
            document.getElementById("leadRow").classList.remove("hidden")
            await this.props.scrollFunction(() => { document.getElementById("leadRow").classList.add("hidden") })
        }
    }

    render() {
        function printData(row, structure) {
            try {
                if (Array.isArray(structure.concat)) {
                    var text = ""
                    structure.concat.forEach(dataName => {
                        text += eval("row." + dataName) + " "
                    })
                    return <span className={"px-3 py-2 flex " + (structure.additionalClass ? structure.additionalClass.join(" ") : "")}> {text} </span>
                } else {
                    if (structure.type && structure.type == "hour") {
                        return <span className={"px-3 py-2 flex " + (structure.additionalClass ? structure.additionalClass.join(" ") : "")}> {formatHour(eval("row." + structure.dataName))} </span>
                    } else if (structure.type && structure.type == "perio") {
                        return <span className={"px-3 py-2 flex " + (structure.additionalClass ? structure.additionalClass.join(" ") : "")}> {formatperio(eval("row." + structure.dataName))} </span>
                    }
                    else {
                        return <span className={"px-3 py-2 flex " + (structure.additionalClass ? structure.additionalClass.join(" ") : "")}> {eval("row." + structure.dataName)} </span>
                    }
                }

            } catch (error) {
                return null
            }
        }

        function formatHour(data) {
            try {/*
                if (data.length == 4) {
                    return ''.concat(data[0], data[1], 'h', data[2], data[3])
                } else*/ if (data.includes(',')) {
                    return data.replace(',', 'h')
                }
                else {
                    return "00h00"
                }
            } catch (error) {
                return "00h00"
            }
        }

        function formatperio(data) {
            try {
                switch (data) {
                    case "J":
                        return "Jour"
                    case "M":
                        return "Matin"
                    case "A":
                        return "Après-midi"
                    default:
                        return "jour"
                }
            } catch (error) {
                return "jour"
            }
        }

        var structure =
            this.state.structureTable.structure.map((structure, key) =>
                <th key={key} className={`bg-[#a5a4bf] whitespace-nowrap z-10 sticky top-0 border-b px-3 py-4 tracking-wider uppercase text-xs text-left border-transparent `
                }> {structure.title}</th >
            )

        var data = this.state.dataTable.map((row, key) =>
            /**obtion de like ici  */
            <tr key={key}
                onClick={() => this.state.optionRow == "link" ? this.state.fonctionRow(row) : false}
                className={`hover:bg-gray-100 text-gray-500 + ${this.state.optionRow == "link" ? "cursor-pointer" : null}`}
            >
                {this.state.structureTable.structure.map((structure, key) =>
                    <td key={key} className="border-b border-gray-100  text-left"> {printData(row, structure)} </td>
                )}
            </tr>
        )

        var leadRow =
            this.state.structureTable.structure.map((structure, key) =>
                <td key={key} className="border-b border-gray-100 px-3 py-2 "> <LoadingBar /> </td>
            )

        return (
            <div onScroll={this.props.scrollFunction ? (element) => this.scrollFunction(element) : null} className={`max-w-[84vw] not-prose relative bg-grid-gray-100 bg-gray-50 h-full overflow-auto ${this.state.maxHeightTable} ${this.state.rounded == "false" ? "" : this.state.rounded}`} >
                <table className="border-collapse table-auto w-full text-sm">
                    <thead className='w-full text-white'>
                        <tr>
                            {structure}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {data}
                        <tr id='leadRow' className={`hover:bg-gray-100 text-gray-500 hidden`} >
                            {leadRow}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default (props) => (
    <DynamicTable
        {...props}
        navigation={useNavigate()}
    />
);