import React, { Component } from 'react';
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

class SearchBarPlanning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navigation: this.props.navigation
        }
        this.searchCLient = this.searchCLient.bind(this)
    }

    async searchCLient(event) {
        const data = new FormData(event.target);
        let arrayData = {}
        for (var pair of data.entries()) {
            arrayData[pair[0]] = pair[1]
        }
        this.state.navigation(`/planning?search=` + arrayData["search"])
    }

    render() {
        return (
            <form className='flex w-full mr-2' onSubmit={this.searchCLient} >
                <div className="flex relative w-full">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <FontAwesomeIcon icon={faSearch} className="text-gray-400 text-[0.7vw] mr-1 my-auto " />
                    </div>
                    <input type="search" name="search" id="search" className="h-full block p-2 pl-10 w-full text-sm rounded-lg border border-white focus:border-white outline-0" placeholder="N°locataire, n°collectif, n°logement, collectif bailleur, nom, prenom" required></input>
                </div>
                <button type="submit" className="right-2.5 bottom-2.5 text-gray-600 hover:text-gray-900 font-medium text-sm px-2 py-2.5 ">Recherche</button>
            </form>
        );
    }
}

export default (props) => (
    <SearchBarPlanning
        {...props}
        navigation={useNavigate()}
    />
);