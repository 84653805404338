import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

export default class ModuleProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displaySecondeProfilBar: false
        }
        this.displaySecondeProfilBar = this.displaySecondeProfilBar.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    displaySecondeProfilBar(display = !this.state.displaySecondeProfilBar) {
        this.setState({
            displaySecondeProfilBar: display
        })
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.displaySecondeProfilBar(false)
        }
    }

    render() {
        return (
            <div ref={this.setWrapperRef} className="flex">
                <div className='cursor-pointer flex space-x-2 my-auto' onClick={() => this.displaySecondeProfilBar()} >
                    <FontAwesomeIcon icon={faChevronDown} className="text-gray-400 text-[0.7vw] my-auto " />
                    <div className='w-5 h-5 bg-blue-100 rounded-full my-auto'>
                    </div>
                </div>
                {this.state.displaySecondeProfilBar
                    ? <div id='secondeProfilBar' className='absolute bg-white w-[13vw] mt-[2.3vw] right-[1.0vw] rounded-sm shadow-md px-[0.4vw] py-[0.5vw] text-gray-600 '>
                        <NavLink to="/logout" className={"flex hover:bg-gray-400 hover:text-white cursor-pointer rounded-sm px-[0.4vw] py-[0.2vw]"}>
                            <FontAwesomeIcon icon={faSignOutAlt} className="my-auto mr-1" />Se déconnecter
                        </NavLink>
                    </div>
                    : null}
            </div>
        );
    }
}

