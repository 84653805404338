import React, { Component } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import Navbar from "../components/navbar"
import Profilebar from "../components/profilebar"
import DynamicTable from "../components/dynamicTable"
import LoadingTable from "../components/loadingTable"
import structureTableBase from '../assets/table/structure_base_planning.json'

class Planning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataUser: this.props.dataUser,
            structureTable: structureTableBase,
            dataTable: null,
            dataTableCount: 0,
            dataPage: 0,
            useSearchParams: this.props.useSearchParams,
            search: "",
            date: "",

        }
        this.getDataTable = this.getDataTable.bind(this);
        this.scrollFunction = this.scrollFunction.bind(this);
        this.dateToday = this.dateToday.bind(this);
        this.searchParams = this.searchParams.bind(this);
        this.getUrl = this.getUrl.bind(this);
        this.getData = this.getData.bind(this);
        this.reset = this.reset.bind(this);
    }

    async componentDidMount() {
        if (!await this.searchParams()) {
            await this.dateToday()
        }
        this.getDataTable();
    }

    searchParams() {
        var [searchParams] = this.state.useSearchParams
        if (searchParams.get('search')) {
            this.setState({
                search: searchParams.get('search')
            })
            searchParams.delete('search')

            document.getElementById('datePlanning').classList.add("cursor-not-allowed", "bg-gray-300")
            document.getElementById('datePlanning').value = null
            return true
        } else {
            return false
        }
    }

    dateToday() {
        var date = new Date
        date = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        var [day, month, year] = date.split('/');
        if (parseInt(month) > 0 && parseInt(month) < 10) {
            month = "0" + month;
        }

        if (parseInt(day) > 0 && parseInt(day) < 10) {
            day = "0" + day;
        }

        this.setState({
            date: [day, month, year].join('/')
        })
        document.getElementById('datePlanning').value = [year, month, day].join('-')
    }

    getUrl(page = 0) {
        var PortailPartenaireAPI = process.env.REACT_APP_PortailPartenaireAPI
        return this.state.search == ""
            ? PortailPartenaireAPI + '/api/i/intebydate?size=50&page=' + page
            : PortailPartenaireAPI + '/api/i/search?size=50&page=' + page;
    }

    getData() {
        return this.state.search == ""
            ? { date: this.state.date }
            : { stringSearch: this.state.search };
    }

    async reset() {
        this.props.navigation('/planning')
        await this.setState({
            search: "",
            date: ""
        });
        await this.dateToday()
        this.getDataTable();
        document.getElementById('datePlanning').classList.remove("cursor-not-allowed", "bg-gray-300")
    }

    getDataTable() {
        var axios = require('axios');
        var tokenJWT = localStorage.getItem("accessToken")

        var config = {
            method: 'post',
            url: this.getUrl(),
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            },
            data: this.getData()
        };
        //console.log(config)
        axios(config).then((response) => {
            //console.log(response.data.rows)
            this.setState({
                dataTable: response.data.rows,
                dataTableCount: response.data.count
            });
        }).catch((error) => {
            //popup 
            //console.log(error)
            /*if (error.response.statusText == "Unauthorized") {
                this.props.navigation('/unauthorizedPage')
            } else {
                this.props.navigation('/logout')
            }*/
        })
    }
    //cette fonction gere le scroll / pagination de la liste des client 
    scrollFunction(callBack = null) {
        //update dataTable
        if (this.state.dataPage < Math.ceil(this.state.dataTableCount / 50)) {
            var page = this.state.dataPage + 1
            var axios = require('axios');
            var tokenJWT = localStorage.getItem("accessToken")

            var config = {
                method: 'post',
                url: this.getUrl(page),
                headers: {
                    'Authorization': 'Bearer ' + tokenJWT
                },
                data: this.getData()
            };

            axios(config).then(async (response) => {
                await this.setState({
                    dataTable: this.state.dataTable.concat(response.data.rows),
                    dataPage: page
                });
                callBack()
            }).catch((error) => {
                console.log(error)
                /*if (error.response.statusText == "Unauthorized") {
                    this.props.navigation('/unauthorizedPage')
                } else {
                    this.props.navigation('/logout')
                }*/
            })
        } else {
            callBack()
        }
    }

    async newDate(event) {
        //console.log(event.target.value)
        var newDate = event.target.value
        var [year, month, day] = newDate.split('-');
        newDate = [day, month, year].join('/')
        await this.setState({
            date: newDate
        })
        this.getDataTable()
    }



    render() {
        //console.log(process.env.REACT_APP_EcommAPI)    
        return (
            <>
                <Navbar where={"planning"} />
                <div className="flex flex-col w-full">
                    <Profilebar username={this.state.dataUser.username} />
                    <div className="px-[3vw] py-[1vw] bg-gray-200 h-full">
                        <h1 className='mb-3 text-xl'> Planning des interventions ({this.state.dataTable == null ? 0 : this.state.dataTableCount}) </h1>
                        <div className='flex justify-between'>
                            <input type={"date"} name="datePlanning" id="datePlanning" onChange={(event) => this.newDate(event)} className='mb-2 px-2 py-1 cursor-pointer' ></input>
                            <div onClick={() => { this.reset() }} className="group  mt-1 mb-auto text-white right-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer"><FontAwesomeIcon icon={faRedoAlt} className="mr-1 group-hover:animate-spin" />Réinitialiser</div>
                        </div>
                        {this.state.dataTable
                            ? <DynamicTable scrollFunction={this.scrollFunction} structureTable={this.state.structureTable} dataTable={this.state.dataTable} maxHeightTable={"max-h-[77vh]"} rounded={"false"} ></DynamicTable>
                            : <LoadingTable numCol={10} numRow={19} rounded={"false"} maxHeightTable={"max-h-[77vh]"} />}
                    </div>
                </div>
            </>
        )
    }
}

export default (props) => (
    <Planning
        {...props}
        navigation={useNavigate()}
        useSearchParams={useSearchParams()}
    />
);