import React, { Component } from 'react';
import NotifBar from '../../components/notifBar';
import { GetDataForm } from '../../components/getDataForm';
import { NavLink } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            AuthAPI: process.env.REACT_APP_AuthAPI,
            displayAlert: false,
            messageAlert: "",
            colorAlert: "",
            accessToken: localStorage.getItem('accessToken'),
            display: this.props.display || "login",
            navigation: this.props.navigation
        }
        this.authenticator = this.authenticator.bind(this)
        this.forgotPassword = this.forgotPassword.bind(this)
        this.display = this.display.bind(this)
        this.upDisplay = this.upDisplay.bind(this)
        this.resetPassword = this.resetPassword.bind(this)
    }

    displayAlert(messageAlert, colorAlert) {
        this.setState({
            displayAlert: !this.state.displayAlert,
            messageAlert: messageAlert,
            colorAlert: colorAlert,
        })
        setTimeout(() => {
            this.setState({
                displayAlert: !this.state.displayAlert
            })
        }, 6000);
    }

    forgotPassword(event) {
        var getDataForm = new GetDataForm(event)
        var jsonData = getDataForm.getJson()

        var env = this
        document.getElementById('spinner').classList.remove("hidden")
        document.body.classList.add('overflow-hidden')

        var config = {
            method: 'post',
            url: process.env.REACT_APP_AuthAPI + '/api/auth/forgotpassword',
            headers: {
                'Content-Type': 'application/json',
                'Content-Security-Policy': 'upgrade-insecure-requests'
            },
            data: jsonData
        };

        axios(config).then(function (response) {
            let jsonData = {
                "to": [
                    {
                        "email": response.data.email
                    }
                ],
                "templateId": 263,
                "params": {
                    "token": response.data.token
                }
            }

            let configSendinblue = {
                method: 'post',
                url: 'https://api.sendinblue.com/v3/smtp/email',
                headers: {
                    'Content-Type': 'application/json',
                    'Content-Security-Policy': 'upgrade-insecure-requests',
                    'api-Key': process.env.REACT_APP_TokenSendblue
                },
                data: jsonData
            };

            axios(configSendinblue)
                .then(function (response) {
                    //console.log(response)
                    env.displayAlert("Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe.", "blue")
                })
                .catch(function (error) {
                    //console.log(error)
                    env.displayAlert("Une erreur est survenue", "red")
                });

            document.getElementById('spinner').classList.add("hidden")
            document.body.classList.remove('overflow-hidden')

        })
            .catch(function (error) {
                //console.log(error)
                env.displayAlert("L'username est invalide", "red")
                document.getElementById('spinner').classList.add("hidden")
                document.body.classList.remove('overflow-hidden')
            });
    }

    resetPassword(event) {
        var getDataForm = new GetDataForm(event)
        var jsonData = {
            "token": this.state.params.token,
            "password": getDataForm.data.password
        }

        var env = this
        document.getElementById('spinner').classList.remove("hidden")
        document.body.classList.add('overflow-hidden')

        var config = {
            method: 'post',
            url: process.env.REACT_APP_AuthAPI + '/api/auth/resetPassword',
            headers: {
                'Content-Type': 'application/json',
                'Content-Security-Policy': 'upgrade-insecure-requests'
            },
            data: jsonData
        };

        axios(config)
            .then(function (response) {
                //console.log(response)
                env.displayAlert("Votre mot de passe a été modifié, vous avez été redirigé sur la page de connexion.", "green")
                document.getElementById('spinner').classList.add("hidden")
                document.body.classList.remove('overflow-hidden')
                document.getElementById('inputNewPassword').removeAttribute('class')
                document.getElementById('inputPassword').removeAttribute('class')
                document.getElementById('inputPassword').classList.add('bg-gray-200', 'appearance-none', 'border-2', 'border-gray-200', 'rounded', 'w-full', 'py-2', 'px-4', 'text-gray-700', 'leading-tight', 'focus:outline-none', 'focus:bg-white', 'focus:border-blue-500')
                document.getElementById('inputNewPassword').classList.add('bg-gray-200', 'appearance-none', 'border-2', 'border-gray-200', 'rounded', 'w-full', 'py-2', 'px-4', 'text-gray-700', 'leading-tight', 'focus:outline-none', 'focus:bg-white', 'focus:border-blue-500')
                env.state.navigation(`/login`)
                env.upDisplay("login")

            })
            .catch(function (error) {
                //console.log(error)
                env.displayAlert("Une erreur est survenue", "red")
                document.getElementById('spinner').classList.add("hidden")
                document.body.classList.remove('overflow-hidden')
            });
    }

    testPassword() {
        var password = document.getElementById('inputPassword')
        var newPassword = document.getElementById('inputNewPassword')

        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/.test(password.value)) {
            password.classList.remove("border-red-500")
            password.classList.add("border-green-500")
        } else if (password.value.length > 0) {
            password.classList.remove("focus:border-blue-500")
            password.classList.remove("border-green-500")
            password.classList.add("border-red-500")
        }

        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/.test(newPassword.value) && newPassword.value === password.value) {
            newPassword.classList.remove("border-red-500")
            newPassword.classList.add("border-green-500")
            document.getElementById('buttonResetPassword').classList.add("cursor-pointer")
            document.getElementById('buttonResetPassword').classList.remove("cursor-not-allowed")
            document.getElementById('buttonResetPassword').disabled = false
        } else if (newPassword.value.length > 0) {
            newPassword.classList.remove("focus:border-blue-500")
            newPassword.classList.remove("border-green-500")
            newPassword.classList.add("border-red-500")
            document.getElementById('buttonResetPassword').classList.remove("cursor-pointer")
            document.getElementById('buttonResetPassword').classList.add("cursor-not-allowed")
            document.getElementById('buttonResetPassword').disabled = true

        }

    }

    async authenticator(event) {
        var env = this
        document.getElementById('spinner').classList.remove("hidden")
        document.body.classList.add('overflow-hidden')

        event.preventDefault();
        const data = new FormData(event.target);
        let arrayData = {}

        for (var pair of data.entries()) {
            arrayData[pair[0]] = pair[1]
        }

        let jsonData = JSON.stringify(arrayData)

        //console.log(jsonData, arrayData)

        var axios = require('axios');

        var config = {
            method: 'post',
            url: process.env.REACT_APP_AuthAPI + '/api/auth/login',
            headers: {
                'Content-Type': 'application/json',
                'Content-Security-Policy': 'upgrade-insecure-requests'
            },
            data: jsonData
        };


        await axios(config)
            .then(function (response) {
                let accessToken = response.data.accessToken

                env.setState({
                    accessToken: accessToken
                })
                localStorage.setItem('accessToken', accessToken)
                if (arrayData["souvenir"] != "on") {
                    localStorage.setItem('deadLineAuth', Math.floor(Date.now() / 1000 + 3600 * 24))
                }
                env.state.navigation(`/`)

            })
            .catch(function (error) {
                //console.log(error)
                env.displayAlert("Votre de mot de passe est incorrect", "red")
            });

        document.getElementById('spinner').classList.add("hidden")
        document.body.classList.remove('overflow-hidden')
    }

    upDisplay(display) {
        this.setState({ display: display })
    }

    display() {
        if (document.getElementById('form'))
            document.getElementById('form').reset();
        switch (this.state.display) {
            case "login":
                return <div className="flex flex-col h-full">
                    <form className=" flex flex-col w-full px-[5.4vw] text-[0.8vw] my-auto" id='form' onSubmit={this.authenticator} encType="application/x-www-form-urlencoded">
                        <div className="flex flex-col mb-6">
                            <label className="block font-bold mb-1 mr-4" htmlFor="inline-full-name"> Adresse mail</label>
                            <input required autoFocus name="username" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" id="inputUsername" type="text" placeholder="entreprise@m-energies.fr" />
                        </div>
                        <div className="flex flex-col mb-3">
                            <label className="block font-bold mb-1 mr-4" htmlFor="inline-password"> Mot de passe </label>
                            <input required className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" type="password" name="password" id="inputPassword" placeholder="******************" />
                        </div>
                        <div className='flex mb-9'>
                            <input type="checkbox" id="souvenir" name="souvenir" value="on" className="mr-1" />
                            <label htmlFor='souvenir'> Rester connecté </label>
                            <span onClick={() => this.upDisplay("forgotPassword")} className='ml-auto cursor-pointer text-blue-500 underline decoration-blue-500 hover:text-blue-400 hover:decoration-blue-400' href='/login'> Mot de passe oublié </span>
                        </div>
                        <div className="flex items-center">
                            <button type="submit" className="bg-gray-400 hover:bg-gray-300 font-bold py-2 px-4 mb-2 rounded cursor-pointer" >
                                Connexion
                            </button>
                        </div>
                    </form>
                </div>
            case "forgotPassword":
                return <div className="flex flex-col h-full px-[5.4vw]">
                    <h1 className='text-[1.1vw] font-semibold mt-auto mb-6 ' >Vous avez oublié votre mot de passe ?</h1>
                    <p className='text-[0.8vw] mb-4'>
                        Veuillez saisir votre adresse email pour recevoir le lien de réinitialisation :
                    </p>
                    <form className=" flex flex-col w-full  mb-auto text-[0.8vw]" id='form' onSubmit={this.forgotPassword} encType="application/x-www-form-urlencoded">
                        <div className="flex flex-col mb-6">
                            <input required autofocus name="username" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" id="inputUsername" type="text" placeholder="Adresse mail" />
                        </div>
                        <div className="flex items-center ">
                            <span onClick={() => this.upDisplay("login")} className="ml-auto mr-4 bg-gray-400 hover:bg-gray-300 font-bold py-2 px-4 mb-2 rounded cursor-pointer" >
                                Retour
                            </span>
                            <button type="submit" className="bg-gray-400 hover:bg-gray-300 font-bold py-2 px-4 mb-2 rounded cursor-pointer" >
                                Envoyer
                            </button>
                        </div>
                    </form>
                </div>
            case "resetPassword":
                return <div className="flex flex-col h-full px-[5.4vw]">
                    <p className='text-[0.8vw] mt-auto mb-4'>
                        Veuillez renseigner votre nouveau mot de passe. (Il doit contenir minimum 8 caractères dont 1 majuscule, 1 chiffre et 1 caractère special)
                    </p>
                    <form className=" flex flex-col w-full  text-[0.8vw] mb-auto" id='form' onSubmit={this.resetPassword} encType="application/x-www-form-urlencoded">
                        <div className="flex flex-col mb-3">
                            <label className="block font-bold mb-1 mr-4" htmlFor="inline-password"> Mot de passe </label>
                            <input onChange={() => this.testPassword()} required className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" type="password" name="password" id="inputPassword" placeholder="******************" />
                        </div>
                        <div className="flex flex-col mb-3">
                            <label className="block font-bold mb-1 mr-4" htmlFor="inline-password">Valider mot de passe </label>
                            <input onChange={() => this.testPassword()} required className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" type="password" name="newPassword" id="inputNewPassword" placeholder="******************" />
                        </div>
                        <div className="flex items-center">

                            <button type="submit" id="buttonResetPassword" disabled className="ml-auto bg-gray-400 hover:bg-gray-300 font-bold py-2 px-4 mb-2 rounded cursor-not-allowed" >
                                Modifier
                            </button>
                        </div>
                    </form>
                </div>
            default:
                return <div className="flex flex-col h-full">
                    <p className='text-[0.8vw] my-auto px-[5.4vw]'>
                        Veuillez utiliser le site correctement
                    </p>
                </div>
        }
    }

    render() {
        return (
            <div className="h-screen flex flex-col w-full">

                <div className="flex content-center my-auto w-full">
                    <div className="mx-auto w-1/2 rounded">

                        {this.state.displayAlert == true ? <div className="h-20 py-4 "><NotifBar display={this.state.displayAlert} color={this.state.colorAlert} message={this.state.messageAlert} ></NotifBar> </div> : null}

                        {this.state.accessToken == null
                            ? <div className='flex w-full '>
                                <img className='w-2/5  rounded-tl rounded-bl ' src={"/images/login.png"}>

                                </img>
                                <div className='flex flex-col w-3/5 bg-dark text-white rounded-tr rounded-br'>
                                    {this.display()}
                                </div>
                            </div>
                            : <div className="flex flex-col items-center justify-center my-2">
                                <span className="mb-2">Vous êtes déjà connecté </span>
                                <logoutButton></logoutButton>
                                <NavLink className="bg-[#a5ce61] bg-blue-600 hover:bg-blue-500 font-bold py-2 px-4 mb-2 rounded cursor-pointer text-white" to={"/"} >
                                    Page accueil
                                </NavLink>
                            </div>
                        }
                    </div>
                </div>


                <div id='spinner' className=" min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div className="flex justify-center items-center h-full">
                        <img className="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
                    </div>
                </div>
            </div>
        );
    }
}

export default (props) => (
    <Login
        {...props}
        params={useParams()}
        navigation={useNavigate()}
    />
);