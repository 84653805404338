import React, { Component } from 'react';
import { Navigate } from 'react-router';

class logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clearUser: this.props.clearUser
        }
        localStorage.clear()
    }

    componentDidMount() {
        this.state.clearUser()
    }

    render() {
        return <Navigate to={'/login'} />
    }
}

export default logout;