import React, { Component } from 'react';
import { downloadString, convertJsonToCsv, convertToHourMinute, getDataTable } from './outilExport';

export default class ExportReclamation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            PortailPartenaireAPI: process.env.REACT_APP_PortailPartenaireAPI,
            ifError: this.props.ifError
        }
    }


    restructureData(listeJson) {
        const restructureData = [];

        listeJson.forEach(item => {
            const existingFacre = restructureData.find(facre => facre["ID_facture"] === item["FACTU_FACTU"]);

            if (existingFacre) {
                existingFacre.pieces.push({ CORFA_REFER: item.CORFA_REFER, CORFA_DESIG: item.CORFA_DESIG, CORFA_QUANT: item.CORFA_QUANT });
            } else {
                restructureData.push({
                    "ID_facture": item["FACTU_FACTU"],
                    "nom": item["CLIEN_NOM"],
                    "prenom": item["CLIEN_PRENO"],
                    "numero": item["clien_numru"],
                    "Type_RUE": item["clien_quaru"],
                    "rue": item["clien_rue1"],
                    "code_postal": item["clien_codpo"],
                    "ville": item["clien_ville"],
                    "regie": item["CLIEN_REGIE"],
                    "ID_client": item["CLIEN_CLIEN"],
                    "date_intervention": item["APPHI_DATIN"],
                    "noreclam": item["APPHI_NORECLAM"],
                    "date_appel": item["APPHI_DATAP"],
                    "heur_appel": convertToHourMinute(item["APPHI_HEURA"]),
                    "libelle": item["APPHI_LIBEL"],
                    pieces: [{ CORFA_REFER: item.CORFA_REFER, CORFA_DESIG: item.CORFA_DESIG, CORFA_QUANT: item.CORFA_QUANT }]
                });
            }
        });

        return restructureData
    }


    async handleClick() {
        var fileName = "Export_reclamation_" + new Date().toLocaleDateString().replace("/", "_")

        try {
            var data = await getDataTable(this.state.PortailPartenaireAPI + "/api/r/currentyear")

            var restructureData = await this.restructureData(data)

            var csv = await convertJsonToCsv(restructureData)

            downloadString(csv, "text/csv", fileName)
        } catch (error) {
            console.error(error);
            this.state.ifError(error.message, 'red')
        }


    }



    render() {

        return (
            <div onClick={() => this.handleClick()} className='flex flex-col bg-white text-gray-600 hover:text-white hover:bg-[#a5a4bf] py-3 pb-8 px-3 rounded cursor-pointer'>
                <p className='mb-4 font-medium'>Liste des reclamations {new Date().getFullYear()}</p>
                <img className='h-[5vw] mx-auto' src={"/images/microsoft-excel.png"} alt="Logo microsoft excel" />
            </div>
        );
    }
}