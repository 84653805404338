import React, { Component } from 'react';
import { downloadString, convertJsonToCsv, getDataTable } from './outilExport';

export default class ExportClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            PortailPartenaireAPI: process.env.REACT_APP_PortailPartenaireAPI,
            ifError: this.props.ifError
        }
    }


    async handleClick() {
        var fileName = "Export_locataires_" + new Date().toLocaleDateString().replace("/", "_")
        var headerClean = ['Numero client', 'Nom', 'Prenom', 'Numero', 'Quaru', 'Rue', 'Code postal', 'Ville', 'Type contrat', 'Immeuble', 'Endat', 'Regie', 'Modele appareil', 'Marque appareil', "Genre de l'appareil", 'Date de mise en service', 'Nagcoll']

        try {
            var data = await getDataTable(this.state.PortailPartenaireAPI + "/api/c/partenaire")

            var csv = await convertJsonToCsv(data, null, headerClean)

            downloadString(csv, "text/csv", fileName)
        } catch (error) {
            console.error(error);
            this.state.ifError(error.message, 'red')
        }

    }


    render() {

        return (
            <div onClick={() => this.handleClick()} className='flex flex-col bg-white text-gray-600 hover:text-white hover:bg-[#a5a4bf] py-3 pb-8 px-3 rounded cursor-pointer'>
                <p className='mb-4 font-medium'>Liste des locataires</p>
                <img className='h-[5vw] mx-auto' src={"/images/microsoft-excel.png"} alt="Logo microsoft excel" />
            </div>
        );
    }
}