

export class GetDataForm {
    constructor(event) {
        let arrayData = {}
            var env = this
            event.preventDefault();
            const data = new FormData(event.target);
            for(var pair of data.entries()) {
                arrayData[pair[0]] = pair[1]
            } 
        this.data = arrayData;
      }
    getArray(){
        return this.data
    }

    getJson(){
        return JSON.stringify(this.data)
    }
    
    
}
