import React, { Component } from 'react';
import LoadingBar from "./loadBar"

export default class LoadingTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rounded: this.props.rounded || "rounded-lg",
            maxHeightTable: this.props.maxHeightTable || "max-h-[81vh]",
            numRow: this.props.numRow || 14,
            numCol: this.props.numCol || 10
        }
    }

    render() {
        return (
            <div className={`not-prose relative bg-grid-gray-100 bg-gray-50 h-full overflow-auto ${this.state.maxHeightTable} ${this.state.rounded == "false" ? "" : this.state.rounded}`} >
                <table className="border-collapse table-auto w-full text-sm">
                    <thead className='w-full text-white '>
                        <tr>
                            {Array.apply(null, Array(this.state.numCol)).map((object, key) =>
                                <th key={key} className="bg-[#a5a4bf] whitespace-nowrap z-10 sticky top-0 border-b px-3 py-4 tracking-wider"> <LoadingBar /></th>
                            )}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {Array.apply(null, Array(this.state.numRow)).map((object, key) => (<tr key={key} className={`hover:bg-gray-100 text-gray-500 `} >
                            {Array.apply(null, Array(this.state.numCol)).map((object, key) =>
                                <td key={key} className="border-b border-gray-100 px-3 py-2 "> <LoadingBar /> </td>
                            )}
                        </tr>))}
                    </tbody>
                </table>
            </div>
        );
    }
}