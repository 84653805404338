import React, { Component } from 'react';
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faBell, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import ModuleProfile from './moduleProfile'
import SearchBarClient from './searchBarClient'
import SearchBarPlanning from './searchBarPlanning'

class Profilebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: this.props.username || "Nom Prenom",
            navigation: this.props.navigation
        }
    }


    whatSearch() {
        switch (window.location.pathname) {
            case "/clients":
                return < SearchBarClient></SearchBarClient>
            case "/planning":
                return < SearchBarPlanning></SearchBarPlanning>
            default:
                return null
        }
    }

    render() {
        return (
            <>
                <div className="flex navbar-light bg-white w-full text-white min-h-[3vw] max-h-[3vw] text-[#343a40] px-5 ">
                    {this.whatSearch()}

                    <div className='flex justify-end py-2.5 space-x-2 ml-auto'>
                        <div className='px-2 my-auto'>
                            <FontAwesomeIcon icon={faBell} className="text-gray-400 text-sm " />
                        </div>
                        <div className=' flex min-w-[9vw] text-sm my-auto whitespace-nowrap'>
                            {this.state.username}
                        </div>
                        <ModuleProfile displaySecondeProfilBar={this.displaySecondeProfilBar}></ModuleProfile>
                    </div>
                </div>
            </>
        );
    }
}

export default (props) => (
    <Profilebar
        {...props}
        navigation={useNavigate()}
    />
);