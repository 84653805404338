import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            where: this.props.where
        }
    }

    render() {
        return (
            <div className="flex flex-col navbar-light bg-dark h-screen  min-w-[10vw] max-w-[10vw] text-white">
                <NavLink to="/" className="flex cursor-pointer px-2 bg-gray-900 h-[3vw] font-bold ">
                    <h1 className=" text-[0.8vw] uppercase m-auto">
                        Portail partenaire
                    </h1>
                </NavLink>
                <nav className="flex flex-col text-[0.6vw] pb-2">
                    <NavLink to="/" className={"font-semibold cursor-pointer px-3 py-2.5 hover:bg-gray-600 border-l-[3px] border-[#343a40] hover:border-inherit" + (this.props.where == "home" ? " bg-gray-600  border-inherit" : "")}>
                        <FontAwesomeIcon icon={faCog} className="text-gray-400 text-[0.7vw] mr-1" />Accueil
                    </NavLink>
                    <NavLink to="/clients" className={"font-semibold cursor-pointer px-3 py-2.5 hover:bg-gray-600 border-l-[3px] border-[#343a40] hover:border-inherit" + (this.props.where == "clients" ? " bg-gray-600 border-inherit" : "")}>
                        <FontAwesomeIcon icon={faCog} className="text-gray-400 text-[0.7vw] mr-1" />Locataires
                    </NavLink>
                    <NavLink to="/planning" className={"font-semibold cursor-pointer px-3 py-2.5 hover:bg-gray-600 border-l-[3px] border-[#343a40] hover:border-inherit" + (this.props.where == "planning" ? " bg-gray-600 border-inherit" : "")}>
                        <FontAwesomeIcon icon={faCog} className="text-gray-400 text-[0.7vw] mr-1" />Planning des interventions
                    </NavLink>
                    <NavLink to="/exports" className={"font-semibold cursor-pointer px-3 py-2.5 hover:bg-gray-600 border-l-[3px] border-[#343a40] hover:border-inherit" + (this.props.where == "exports" ? " bg-gray-600 border-inherit" : "")}>
                        <FontAwesomeIcon icon={faCog} className="text-gray-400 text-[0.7vw] mr-1" />Exports
                    </NavLink>

                </nav>
            </div>
        );
    }
}

export default (props) => (
    <Navbar
        {...props}
        navigation={useNavigate()}
    />
);