import React, { Component } from 'react';
import { NavLink } from "react-router-dom"
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import Navbar from "../components/navbar"
import Profilebar from "../components/profilebar"
import DynamicTable from "../components/dynamicTable"
import structureTableBase from '../assets/table/structure_base_liste_intervention_fiche_client.json'
import LoadingBar from "../components/loadBar"
import LoadingTable from "../components/loadingTable"
import NotifBar from '../components/notifBar';
require('../../node_modules/tailwindcss/colors');

class FicheClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            dataUser: this.props.dataUser,
            structureTable: structureTableBase,
            listeIntervention: null,
            ficheClient: null,
            //alert
            displayAlert: false,
            messageAlert: "",
            colorAlert: "",

        }
        this.refreshTable = this.refreshTable.bind(this)
        this.getDataTable = this.getDataTable.bind(this);
        this.displayData = this.displayData.bind(this);
        this.fonctionRow = this.fonctionRow.bind(this);
    }

    async componentDidMount() {
        await this.getLibelleCodeRetour()
        this.getDataTable()
    }


    async refreshTable() {
        await this.getDataTable()
    }

    async getDataTable() {
        var axios = require('axios');
        var PortailPartenaireAPI = process.env.REACT_APP_PortailPartenaireAPI
        var tokenJWT = localStorage.getItem("accessToken")

        var config = {
            method: 'get',
            url: PortailPartenaireAPI + '/api/c/detail/' + this.state.params.idClient,
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        await axios(config).then(response => {
            this.setState({
                listeIntervention: this.getListeIntervention(response.data.OXYG_FACTU),
                ficheClient: response.data
            });
        }).catch(error => {
            console.log(error)
            /*
            if (error.response.statusText == "Unauthorized") {
                this.props.navigation('/unauthorizedPage')
            } else {
                this.props.navigation('/logout')
            }*/
        })
    }

    getLibelleCodeRetour() {
        var axios = require('axios');
        var PortailPartenaireAPI = process.env.REACT_APP_PortailPartenaireAPI
        var tokenJWT = localStorage.getItem("accessToken")

        var config = {
            method: 'get',
            url: PortailPartenaireAPI + '/api/inter',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };
        axios(config).then(response => {
            var objet = {};
            response.data.forEach(element => {
                objet[element.INTER_INTER] = element.INTER_DESIG;
            });

            //console.log(objet)

            this.setState({
                libelleCodeRetour: objet
            });
        })
    }

    getListeIntervention(listeIntervention) {
        var listeCorrespondance = this.state.libelleCodeRetour

        for (var intervention of listeIntervention) {
            if (intervention.FACTU_RETO_1_ in listeCorrespondance) {
                intervention.FACTU_RETO_1_ = listeCorrespondance[intervention.FACTU_RETO_1_];
            }
            if (intervention.FACTU_RETO_2_ in listeCorrespondance) {
                intervention.FACTU_RETO_2_ = listeCorrespondance[intervention.FACTU_RETO_2_];
            }
            if (intervention.FACTU_RETO_3_ in listeCorrespondance) {
                intervention.FACTU_RETO_3_ = listeCorrespondance[intervention.FACTU_RETO_3_];
            }
        }

        return listeIntervention

    }

    displayData(data, type) {
        try {
            if (eval(data) != undefined) {
                switch (type) {
                    case 'tel':
                        return <a href={"tel:" + eval(data)} className="ml-1 cursor-pointer text-blue-400 hover:underline"> {eval(data)}</ a>
                        break;
                    case "email":
                        return <a href={"mailto:" + eval(data)} className="ml-1 cursor-pointer text-blue-400 hover:underline"> {eval(data)}</ a>
                        break;
                    default:
                        return eval(data)
                }

            } else {
                return <span className='ml-2 flex my-auto'><LoadingBar maxWidth={"w-[7vw]"} /></span>
            }
        } catch (error) {
            return <span className='ml-2 flex my-auto'><LoadingBar maxWidth={"w-[7vw]"} /></span>
        }
    }

    async fonctionRow(row) {
        document.getElementById('spinner').classList.remove("hidden")
        var fileName = row.FACTU_FACTU
        var fileType = "text/pdf"

        //var partenaire = this.state.dataUser.roles.find(r => r.match(/ROLE_PARTENAIRE_.*/)).split('_').slice(-1)[0]
        var path = fileName + ".pdf"
        console.log(path)
        var downloadUrl = await this.getDownloadUrlOneDrive(path)

        //console.log(this.state.dataUser)
        if (downloadUrl != undefined) {
            try {
                var a = document.createElement('a');
                a.download = fileName;
                a.href = downloadUrl;
                a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setTimeout(function () { URL.revokeObjectURL(a.href); }, 1500);
                document.getElementById('spinner').classList.add("hidden")
            } catch (error) {
                this.displayAlert("Une erreur est survenue lorsque du téléchargement du bon d’intervention", "red")
                document.getElementById('spinner').classList.add("hidden")
            }
        } else {
            document.getElementById('spinner').classList.add("hidden")
            this.displayAlert("Une erreur est survenue lorsque de la récupération du bon d’intervention, fichier introuvable", "red")
        }

    }


    getDownloadUrlOneDrive(fileName) {
        var axios = require('axios');
        var tokenJWT = localStorage.getItem("accessToken")
        var config = {
            method: 'post',
            url: process.env.REACT_APP_GatewayAzureAPI + "/api/onedrive/getDataItem",
            headers: {
                "Authorization": "Bearer " + tokenJWT
            },
            data: {
                "fileName": fileName,
            }
        }

        return axios(config).then((response) => {
            return response.data['@microsoft.graph.downloadUrl']
        }).catch((error) => {
            //popup 
            console.log(error)

        })
    }

    displayAlert(messageAlert, colorAlert) {
        this.setState({
            displayAlert: !this.state.displayAlert,
            messageAlert: messageAlert,
            colorAlert: colorAlert,
        })
        setTimeout(() => {
            this.setState({
                displayAlert: !this.state.displayAlert
            })
        }, 6000);
    }


    render() {
        //console.log(this.state.structureTable, this.state.dataTable )    
        return (
            <>
                <Navbar where={"clients"} />
                <div className="flex flex-col w-full min-w-[80vw] max-w-[90vw] h-screen">
                    <Profilebar username={this.displayData("this.state.dataUser.username")} />
                    <div className="px-[3vw] py-[1vw] bg-gray-200 h-full ">
                        <div className='flex justify-between'>
                            <h1 className='mb-5 text-xl'> Fiche Locataires  </h1>
                            {this.state.displayAlert == true ? <div className=" w-fit  mb-2"><NotifBar px={"px-4"} py={"py-1"} display={this.state.displayAlert} color={this.state.colorAlert} message={this.state.messageAlert} ></NotifBar> </div> : null}
                            <NavLink to={'/clients'} className=" mt-1 mb-auto"><button type="submit" className="text-white right-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 font-medium text-sm px-2 py-1"><FontAwesomeIcon icon={faCaretLeft} className="mr-1" />Retour</button></NavLink>
                        </div>
                        <div className='flex bg-white px-[3vw] h-[20vh] max-h-[20vh] '>
                            <div className='my-auto flex w-full '>
                                <div className='flex flex-col '>
                                    <span className='flex text-xl font-bold text-[#343a40] mb-1'>{this.displayData("this.state.ficheClient.CLIEN_NOM")} {this.displayData("this.state.ficheClient.CLIEN_PRENO")} </span>
                                    <p className='flex'>
                                        {this.displayData("this.state.ficheClient.CLIEN_NUMRU")} {this.displayData("this.state.ficheClient.CLIEN_QUARU")} {this.displayData("this.state.ficheClient.CLIEN_RUE1")} {this.displayData("this.state.ficheClient.CLIEN_CODPO")} {this.displayData("this.state.ficheClient.CLIEN_VILLE")}
                                    </p>
                                    <p className='flex'>
                                        Téléphone mobile: {this.displayData("this.state.ficheClient.CLIEN_TELMO")}
                                    </p>
                                    <p className='flex'>
                                        Téléphone domicile: {this.displayData("this.state.ficheClient.CLIEN_TELDO")}
                                    </p>
                                    <p className='flex'>
                                        E-mail: {this.displayData("this.state.ficheClient.CLIEN_EMAIL", "email")}
                                    </p>
                                </div>
                                <div className='flex ml-auto space-x-8 '>
                                    <div className='flex flex-col'>
                                        {/*<p>
                                            <span className='font-bold text-[#343a40]'>Dernière VE: </span>"pas de data"
                                        </p>*/}
                                        <p>
                                            <span className='font-bold text-[#343a40]'>Statut contrat: </span><span className='inline-block' >{this.displayData("this.state.ficheClient.CLIEN_CONTR") == "O" ? "sous contrat" : "sans contrat"}</span>
                                        </p>
                                        <p >
                                            <span className='font-bold text-[#343a40]'>Numéro locataire: </span> <span className='inline-block' >{this.displayData("this.state.ficheClient.CLIEN_CLIEN")}</span>
                                        </p>
                                        <p >
                                            <span className='font-bold text-[#343a40]'>Numéro collectif: </span> <span className='inline-block' >{this.displayData("this.state.ficheClient.CLIEN_IMMEU")}</span>
                                        </p>
                                        <p >
                                            <span className='font-bold text-[#343a40]'>Numéro logement: </span> <span className='inline-block' >{this.displayData("this.state.ficheClient.CLIEN_REGIE")}</span>
                                        </p>
                                        <p >
                                            <span className='font-bold text-[#343a40]'>Collectif bailleur: </span> <span className='inline-block' >{this.displayData("this.state.ficheClient.OXYG_IMMEU.IMMEU_NAGCOLL")}</span>
                                        </p>
                                    </div>
                                    <div className='flex flex-col'>
                                        <p className='font-bold text-[#343a40]'>
                                            Tiers :
                                        </p>
                                        <p>
                                            {this.displayData("this.state.ficheClient.OXYG_TIER.TIERS_NOM")}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {this.state.listeIntervention
                            ? <DynamicTable structureTable={this.state.structureTable} dataTable={this.state.listeIntervention} rounded={"false"} maxHeightTable={"max-h-[58vh]"} optionRow={"link"} fonctionRow={this.fonctionRow} ></DynamicTable>
                            : <LoadingTable numCol={7} numRow={9} rounded={"false"} maxHeightTable={"max-h-[58vh]"} />}
                    </div>
                </div>
                <div id='spinner' className=" min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div className="flex justify-center items-center h-full">
                        <img className="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
                    </div>
                </div>
            </>
        )
    }
}

export default (props) => (
    <FicheClient
        {...props}
        params={useParams()}
    />
);