import React, { Component } from 'react';
import { downloadString, convertJsonToCsv, getDataTable } from './outilExport';

export default class ExportPanne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            PortailPartenaireAPI: process.env.REACT_APP_PortailPartenaireAPI,
            ifError: this.props.ifError,
            ListMonth: [
                "janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"
            ],
        }
    }


    getLibelleCodeRetour() {
        if (!this.state.libelleCodeRetour) {
            var axios = require('axios');
            var PortailPartenaireAPI = process.env.REACT_APP_PortailPartenaireAPI
            var tokenJWT = localStorage.getItem("accessToken")

            var config = {
                method: 'get',
                url: PortailPartenaireAPI + '/api/inter',
                headers: {
                    'Authorization': 'Bearer ' + tokenJWT
                }
            };
            return axios(config).then(async (response) => {
                var objet = {};
                await response.data.forEach(element => {
                    objet[element.INTER_INTER] = element.INTER_DESIG;
                });

                await this.setState({
                    libelleCodeRetour: objet
                });
                return objet
            })
        } else {
            return this.state.libelleCodeRetour
        }
    }

    async cleanData(listeIntervention) {

        var listeCorrespondance = await this.getLibelleCodeRetour()

        for (var intervention of listeIntervention) {
            if (intervention.FACTU_RETO_1_ in listeCorrespondance) {
                intervention.FACTU_RETO_1_ = listeCorrespondance[intervention.FACTU_RETO_1_];
            }
            if (intervention.FACTU_RETO_2_ in listeCorrespondance) {
                intervention.FACTU_RETO_2_ = listeCorrespondance[intervention.FACTU_RETO_2_];
            }
            if (intervention.FACTU_RETO_3_ in listeCorrespondance) {
                intervention.FACTU_RETO_3_ = listeCorrespondance[intervention.FACTU_RETO_3_];
            }
        }

        return listeIntervention
    }


    async handleClick() {

        var fileName = "Export_pannes_" + this.state.ListMonth[this.props.month] + "_" + this.props.years
        var header = ['FACTU_FACTU', 'FACTU_TECHN', 'FACTU_DATEF', 'FACTU_RETO_1_', 'FACTU_RETO_2_', 'FACTU_RETO_3_', 'OXYG_FACTUDIV.FACTUDIV_OBSE1', 'OXYG_FACTUDIV.FACTUDIV_OBSE2', 'OXYG_FACTUDIV.FACTUDIV_OBSE3', 'OXYG_FACTUDIV.FACTUDIV_OBSE4', 'OXYG_FACTUDIV.FACTUDIV_OBSE5', 'OXYG_FACTUDIV.FACTUDIV_ORIGLIB', 'FACTU_ARRIV', 'FACTU_DEPAR', 'OXYG_CLIEN.CLIEN_CLIEN', 'OXYG_CLIEN.CLIEN_NOM', 'OXYG_CLIEN.CLIEN_PRENO', 'OXYG_CLIEN.CLIEN_NUMRU', 'OXYG_CLIEN.CLIEN_QUARU', 'OXYG_CLIEN.CLIEN_RUE1', 'OXYG_CLIEN.CLIEN_CODPO', 'OXYG_CLIEN.CLIEN_VILLE', 'OXYG_CLIEN.CLIEN_IMMEU', 'OXYG_CLIEN.CLIEN_REGIE', 'OXYG_CLIEN.OXYG_IMMEU.IMMEU_NAGCOLL']
        var headerClean = ['Numero facture', 'Technicien', 'Date facture', 'Retour 1', 'Retour 2', 'Retour 3', 'Observation 1', 'Observation 2', 'Observation 3', 'Observation 4', 'Observation 5', 'Origlib', 'Heur arriver', 'Heur depart', 'Numero client', 'Nom', 'Prenom', 'Numero', 'Quaru', 'Rue', 'Code postal', 'Ville', 'Immeuble', 'Regie', 'Nagcoll']

        try {
            var data = await getDataTable(this.state.PortailPartenaireAPI + "/api/f/BreakdownsOverBydate/" + this.props.years + "/" + ((this.props.month + 1) > 9 ? (this.props.month + 1) : ("0" + (this.props.month + 1))))

            var dataClean = await this.cleanData(data)

            var csv = await convertJsonToCsv(dataClean, header, headerClean)

            downloadString(csv, "text/csv", fileName)
        } catch (error) {
            console.error(error);
            this.state.ifError(error.message, 'red')
        }

    }


    render() {

        return (
            <div onClick={() => this.handleClick()} className='flex flex-col bg-white text-gray-600 hover:text-white hover:bg-[#a5a4bf] py-3 pb-8 px-3 rounded cursor-pointer'>
                <p className='mb-4 font-medium'>Liste des pannes</p>
                <img className='h-[5vw] mx-auto' src={"/images/microsoft-excel.png"} alt="Logo microsoft excel" />
            </div>
        );
    }
}