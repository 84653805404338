import React from 'react';
import { NavLink } from 'react-router-dom';
import { Component } from 'react';

class errorPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }

  }

  functionButton() {
    document.getElementById('spinner').classList.remove("hidden")
    document.body.classList.add('overflow-hidden')

    localStorage.clear()
    window.location.replace("/login");

    document.body.classList.remove('overflow-hidden')

  }

  render() {
    return (
      <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 m-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-20 mb-12">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-blue-600 sm:text-5xl">
              401
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  vous n'avez pas les droits
                </h1>
                <p className="mt-1 text-base text-gray-500">
                </p>
              </div>
              <div className="mt-10 flex space-x-1 sm:border-l sm:border-transparent sm:pl-6">
                <div onClick={this.functionButton}
                  className="bg-[#a5ce61] bg-blue-600 hover:bg-blue-500 font-bold py-2 px-4 mb-2 rounded cursor-pointer text-white">
                  Me déconnecter
                </div>
              </div>
            </div>
          </main>
        </div>
        <div id='spinner' className=" min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
          <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
          <div className="flex justify-center items-center h-full">
            <img className="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default errorPage;