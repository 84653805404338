import React, { Component } from 'react';
import { Navigate, Route } from "react-router-dom";

class authenticator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            REACT_APP_AuthAPI: process.env.REACT_APP_AuthAPI,
            getUser: this.props.getUser,
            setUser: this.props.setUser,
            ArrayRoles: this.props.ArrayRoles,
            UserTokenJWT: localStorage.getItem("accessToken"),
            authorized: 'wait'  //wait, authorized, unauthorized, unauthenticate, token expirete (a faire)
        }
        //console.log(this.state.ArrayRoles)
    }

    async componentDidMount() {
        if (typeof this.state.UserTokenJWT != 'undefined' && this.state.UserTokenJWT != null) {
            this.verifyRoles(await this.dataUser())
        } else {
            this.setState({
                authorized: 'unauthenticate'
            })
        }

    }

    async dataUser() {
        var dataUser = this.state.getUser()
        if (dataUser == null) {
            var env = this
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.state.REACT_APP_AuthAPI + '/api/auth/validateToken',
                headers: {
                    'Authorization': 'Bearer ' + this.state.UserTokenJWT,
                    'Content-Type': 'application/json'
                }
            };
            var dataUser = null;

            await axios(config).then((response) => {
                //console.log(response)
                env.state.setUser(response.data)
                env.setState({
                    dataUser: response.data,
                    deadLineAuth: localStorage.getItem("deadLineAuth") ? localStorage.getItem("deadLineAuth") : response.data.exp
                })
                dataUser = response.data
            })
            return dataUser
        } else {
            this.setState({
                dataUser: dataUser,
                deadLineAuth: localStorage.getItem("deadLineAuth") ? localStorage.getItem("deadLineAuth") : dataUser.exp
            })
            return dataUser
        }

    }

    verifyRoles(dataUser) {
        if (dataUser.name == "TokenExpiredError" || this.deadLineAuth < Math.floor(Date.now() / 1000)) {
            window.location.replace("/logout");
        } else {
            var env = this
            let authorized = 'unauthorized'
            dataUser.roles.forEach(role => {
                if (env.state.ArrayRoles.includes(role)) {
                    authorized = 'authorized'
                }
            });
            env.setState({
                authorized: authorized
            })
        }
    }

    render() {

        //return null;

        return this.state.authorized == 'wait' ? null
            : this.state.authorized == 'authorized' ? React.cloneElement(this.props.children, { dataUser: this.state.getUser() })
                : this.state.authorized == 'unauthorized' ? <Navigate to="/unauthorizedPage" />
                    : this.state.authorized == 'unauthenticate' ? <Navigate to="/login" />
                        : null
    }
}

export default authenticator;
